<script>
export default {
  name: "PostMini",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000); // Умножаем на 1000, если timestamp в секундах
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
  },
  
};
</script>
<template>
  <router-link :to="'/post/' + item.id" class="news__item">
    <img :src="apiDomain + 'web/uploads/' + item.title_photo" alt="news" class="news__item__image" />
    <div class="news__item--text">
      <div class="news__item__date">
        {{ formatDate(item.date_add) }}
      </div>
      <div class="news__item__title">
        {{ item.title }}
      </div>
      <div class="news__item__subtitle">
        {{ item.short_text }}
      </div>
      <div class="news__item__link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
        >
          <path
            d="M5.25 21H36.75"
            stroke="#EC4E44"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.5 26.25L36.75 21L31.5 15.75"
            stroke="#EC4E44"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </router-link>
</template>
<style scoped>
.news__item {
  display: flex;
  flex-direction: column;
  background: #333;
  max-width: calc(33.333% - 17px);
  min-height: 419px;
  width: 373px;
  height: 100%;
}
.news__item__image {
  width: 100%;
  height: 198px;
  object-fit: cover;
  object-position: center;
}
.news__item--text {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 20px;
}
.news__item__date {
  color: rgba(255, 255, 255, 0.34);
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.news__item__title {
  color: var(--Neutral-100, #fff);
  font-family: Onest;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.36px;
}
.news__item__subtitle {
  color: var(--Neutral-100, #fff);
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news__item__link svg {
    transition: 0.3s;
}
.news__item__link:hover svg {
    transform: scale(1.2);
}
@media screen and (max-width: 600px) {
  .news__item {
    max-width: 290px;
    min-height: 414px;
  }
}
</style>
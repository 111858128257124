<script>
import HeaderBlock from "../blocks/HeaderBlock.vue";
import HomeThemBlock from "../blocks/HomeThemBlock.vue";
import HomeAboutBlock from "../blocks/HomeAboutBlock.vue";
import HomeVideoBlock from "../blocks/HomeVideoBlock.vue";
import homeTestBlock from "../blocks/homeTestBlock.vue";
import HomeBaseBlock from "../blocks/HomeBaseBlock.vue";
import PromoTheme from "../blocks/PromoTheme.vue";
import homeNewsBlock from "../blocks/homeNewsBlock.vue";
import SubscribeBlock from "../blocks/SubscribeBlock.vue";
import FooterBlock from "../blocks/FooterBlock.vue";
import HomeMain from "../blocks/HomeMain.vue";
export default {
  name: "HomePage",
  components: {
    HeaderBlock,
    HomeThemBlock,
    HomeAboutBlock,
    HomeVideoBlock,
    homeTestBlock,
    HomeBaseBlock,
    PromoTheme,
    homeNewsBlock,
    SubscribeBlock,
    FooterBlock,
    HomeMain
  },
};
</script>
<template>
  <HeaderBlock />
  <HomeMain />
  <HomeThemBlock />
  <HomeAboutBlock />  
  <HomeVideoBlock />
  <homeTestBlock />
  <HomeBaseBlock />
  <PromoTheme />
  <homeNewsBlock />
  <SubscribeBlock />
  <FooterBlock />

</template>
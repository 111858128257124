<script>
export default {
  name: "BreadcrumbsUi",
  props: {
    customClass: {
      type: String,
      default: "",
    },
    page: {
      type: Array,
      required: true,
    },
    bannerHead: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          id: 1,
          link: "/",
          name: "Главная",
        },
      ],
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  mounted() {
    this.updateBreadcrumbs();
  },
  watch: {
    page: {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true, // Для немедленного запуска при загрузке
    },
  },
  methods: {
    truncate(text, maxLength) {
      if (!text) return "";
      return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          id: 1,
          link: "/",
          name: "Главная",
        },
        ...this.page.map((p, index) => ({
          id: index + 2,
          link: "/" + p.link,
          name: p.name,
        })),
      ];
    },
  },
};

</script>

<template>
  <div :class="'breadcrumbs'">
    <div
      v-if="bannerHead.uniq"
      class="breadcrumbs__wrapper"
      :style="
        `background: url(` +
        apiDomain +
        `web/uploads/` +
        bannerHead.name +
        `) no-repeat center center / cover`
      "
    >
      <div class="container">
        <div class="breadcrumbs__content">
          <div class="head-title">
            <h1 class="head-h1">{{ title }}</h1>
          </div>
          <div class="breadcrumbsLinks">
            <a
              class="breadcrumbs__item"
              v-for="breadcrumb in breadcrumbs"
              :key="breadcrumb.id"
              :href="breadcrumb.link"
            >
            {{ truncate(breadcrumb.name, 20) }}
            </a>
          </div>

          <div v-if="date" class="date">{{ date }}</div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="breadcrumbs__wrapper"
      :style="`background: url(${require('@/assets/image/' +
         bannerHead.name +
        '-ban.png')}) no-repeat center center / cover`"
    >
      <div class="container">
        <div class="breadcrumbs__content">
          <div class="head-title">
            <h1 class="head-h1">{{ title }}</h1>
          </div>
          <div class="breadcrumbsLinks">
            <a
              class="breadcrumbs__item"
              v-for="breadcrumb in breadcrumbs"
              :key="breadcrumb.id"
              :href="breadcrumb.link"
            >
            {{ truncate(breadcrumb.name, 20) }}
            </a>
          </div>

          <div v-if="date" class="date">{{ date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.head-title{
  align-self: flex-end;
}
.head-h1 {
  color: #fff;
  font-family: Onest;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  max-width: 574px;
  text-align: right;
}
.date {
  color: #fff;
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.breadcrumbs__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: max-content;
  align-items: flex-end;
}
.breadcrumbs {
  padding-top: 87px;
}
.breadcrumbs__wrapper {
  padding: 20px;
}
.breadcrumbs__wrapper .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.breadcrumbsLinks {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 18px;
  width: max-content;
  align-self: flex-end;
}
.breadcrumbs__item {
  color: #fff;
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  white-space: nowrap;
}
.breadcrumbs__item:not(:last-child):after {
  content: "/";
  position: absolute;
  top: 0;
  right: -12px;
}

.custom-breadcrumbs {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1024px) {
  .head-h1 {
    font-size: 26px;
  }
  .container {
    padding: 0 20px;
    overflow: hidden;
  }
}
@media screen and (max-width: 560px) {
  .custom-breadcrumbs {
    display: block;
  }
}
</style>
